* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Vazir';
	src: url('../../assets/fonts/Vazir/Vazirmatn-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Vazir', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	/* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #5e6673;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #5e6673;
}

a {
	text-decoration: none;
	appearance: none;
}
